import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;
export default class ProductService {
  async filtrarKardexs(datos) {
    return await fetchWrapper.post(`${ruta}/filtros_kardex`, datos);
  }

  async generarPDF(datos) {
    return await fetchWrapper.postPdf(`${ruta}/reporte/kardex/imprimir`, datos);
  }

  async exportKardexs(datos) {
    return await fetchWrapper.postWithBlob(`${ruta}/reporte/kardex/exportar`, datos);
  }

  async exportDifStockKardexs(datos) {
    return await fetchWrapper.postWithBlob(`${ruta}/reporte/kardex/dif_stock`, datos);
  }
}
